import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface IOmniButtonProps {
    children: string,
    type?: 'submit',
    disabled?: boolean,
    handleClick?: () => void,
    variant?: 'contained' | 'text',
}

export const OmniButton = ({ children, type, disabled, handleClick, variant }: IOmniButtonProps) => (
    <Button variant={variant ?? 'contained'} type={type} disabled={disabled} onClick={handleClick}>
        {
            <Typography noWrap>
                {children}
            </Typography>
        }
    </Button>
);
