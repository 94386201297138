import { axiosService } from '../axios';

interface IStartSingleConversationDataContract {
    agentId: string,
    msisdn: string,
    broadcastToken: string,
    metadata: Record<string, string>,
}

const startSingleConversation = (param: IStartSingleConversationDataContract): Promise<void> => (
    axiosService.post('/conversation/start/single', param)
);

export { startSingleConversation };
