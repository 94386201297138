import { addTester } from '../api/tester/addTester';

interface IEnrollParam {
    phoneNumber: string,
    agentId: string,
}

const enroll = (param: IEnrollParam): Promise<void> => (
    addTester({
        msisdn: param.phoneNumber,
        agentId: param.agentId,
    })
);

export { enroll };
