import Typography from '@mui/material/Typography';

import { FlexBox } from '../display/Flexbox';

interface ILabelProps {
    children: string,
    isBold?: boolean,
    isCentered?: boolean,
}

export const Label = ({ children, isBold, isCentered }: ILabelProps) => (
    <FlexBox align={isCentered ? 'center' : 'left'}>
        <Typography noWrap fontWeight={isBold ? 'bold' : undefined}>
            {children}
        </Typography>
    </FlexBox>
);
