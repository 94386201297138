
export type EnvironmentType = 'local' | 'development' | 'staging' | 'production';
export interface IEnvironment extends Record<EnvironmentType, IEnvironmentAgent[]> {
    local: IEnvironmentAgent[],
    development: IEnvironmentAgent[],
    staging: IEnvironmentAgent[],
    production: IEnvironmentAgent[],
}
export interface IEnvironmentAgent {
    token: string,
    agentId: string,
}

const agents: IEnvironment = {
    local: [
        {
            token: '606d56e1-fcfd-48b0-a3bf-e2f810eb9553',
            agentId: 'opportunity-local@rbm.goog',
        },
        {
            token: 'a1434c7f-9612-41a6-8130-a484000f203f',
            agentId: 'maif-cukflc@rbm.goog',
        },
    ],
    development: [
        {
            token: '606d56e1-fcfd-48b0-a3bf-e2f810eb9553',
            agentId: 'opportunity_dev@rbm.goog',
        },
        {
            token: 'a1434c7f-9612-41a6-8130-a484000f203f',
            agentId: 'maif-cukflc@rbm.goog',
        },
    ],
    staging: [
        {
            token: '606d56e1-fcfd-48b0-a3bf-e2f810eb9553',
            agentId: 'opportunity-staging@rbm.goog',
        },
        {
            token: 'a1434c7f-9612-41a6-8130-a484000f203f',
            agentId: 'maif-cukflc@rbm.goog',
        },
    ],
    production: [
        {
            token: '606d56e1-fcfd-48b0-a3bf-e2f810eb9553',
            agentId: 'oppy@rbm.goog',
        },
        {
            token: 'a1434c7f-9612-41a6-8130-a484000f203f',
            agentId: 'maif-cukflc@rbm.goog',
        },
    ],
};

export default agents;
