import { useCallback } from 'react';

import MuiPhoneNumber from 'material-ui-phone-number';

import './PhoneNumberForm.css';

interface IPhoneNumberFormProps {
    name: string,
    value?: string,
    setFieldValue: (name: string, value: unknown) => void,
    errorValue?: string,
}

export const PhoneNumberForm = ({
    name,
    value,
    setFieldValue,
    errorValue,
}: IPhoneNumberFormProps) => {
    const handleChange = useCallback((phoneNumber) => {
        setFieldValue(name, phoneNumber);
    }, [setFieldValue, name]);

    return (
        <MuiPhoneNumber
            autoFormat={false}
            variant="outlined"
            defaultCountry="fr"
            name={name}
            value={value}
            onChange={handleChange}
            error={errorValue !== undefined}
        />
    );
};
