import Box from '@mui/material/Box';

interface IFlexBoxProps {
    children: React.ReactNode,
    align?: 'center' | 'left',
}

export const FlexBox = ({ children, align }: IFlexBoxProps) => (
    <Box display="flex" justifyContent={align ?? 'center'}>
        {children}
    </Box>
);
