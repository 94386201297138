import { useState, useCallback } from 'react';

import { FormController, IFormErrors } from '../../../components/form/FormController';
import { OmniButton } from '../../../components/ui/buttons/OmniButton';
import { PhoneNumberForm } from '../../../components/ui/forms/PhoneNumberForm';
import { Alert } from '../../../components/ui/texts/Alert';
import { Label } from '../../../components/ui/texts/Label';
import { Paragraph } from '../../../components/ui/texts/Paragraph';
import { enroll } from '../../../services/interactors/enroll';

interface IEnrollFormModel {
    phoneNumber: string,
}

const defaultEnrollFormValues: IEnrollFormModel = {
    phoneNumber: '',
};

interface IEnrollFormProps {
    agentId: string,
    handleEnrollSuccess: (phoneNumber: string) => void,
}

export const enrollFormValidator = (values: Partial<IEnrollFormModel>): IFormErrors<IEnrollFormModel> => ({
    phoneNumber: (() => {
        if (values.phoneNumber === undefined || values.phoneNumber.trim() === '') {
            return 'Mandatory';
        }

        if (!values.phoneNumber.startsWith('+')) {
            return 'Should start with +';
        }

        return undefined;
    })(),
});

export const EnrollForm = ({ agentId, handleEnrollSuccess }: IEnrollFormProps) => {
    const [isInError, setIsInError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = useCallback((formValues: Partial<IEnrollFormModel>) => {
        const parameter = {
            agentId,
            phoneNumber: formValues.phoneNumber ?? '',
        };

        setIsLoading(true);
        setIsInError(false);

        enroll(parameter)
            .then(() => {
                handleEnrollSuccess(parameter.phoneNumber);
            })
            .catch(() => {
                setIsInError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [agentId, handleEnrollSuccess]);

    return (
        <>
            <Paragraph>{'You\'re about to enroll for testing the following RCS agent.'}</Paragraph>
            <Label isBold isCentered>{agentId}</Label>
            <Paragraph>{'Please complete your phone number to enroll.'}</Paragraph>
            <FormController
                initialValues={defaultEnrollFormValues}
                onSubmit={handleSubmit}
                validator={enrollFormValidator}
            >
                {
                    ({ setFieldValue, values, errors, hasValidationErrors }) => (
                        <>
                            <PhoneNumberForm
                                value={values.phoneNumber}
                                name="phoneNumber"
                                setFieldValue={setFieldValue}
                                errorValue={errors.phoneNumber}
                            />
                            {
                                isInError && (
                                    <Alert severity="error">An error occured. Please retry.</Alert>
                                )
                            }
                            {
                                hasValidationErrors && (
                                    <Alert severity="error">
                                        A validation error occurred. Check the fields in error.
                                    </Alert>
                                )
                            }
                            <OmniButton type="submit" disabled={isLoading}>Enroll</OmniButton>
                        </>
                    )
                }
            </FormController>
        </>
    );
};
