const config: IPerEnvironmentAppConfiguration = {
    local: {
        api: {
            endpoint: 'https://localhost:444/',
        },
    },
    development: {
        api: {
            endpoint: 'https://development.enrollment.rcs.oppy.ai/',
        },
    },
    staging: {
        api: {
            endpoint: 'https://staging.enrollment.rcs.oppy.ai/',
        },
    },
    production: {
        api: {
            endpoint: 'https://enrollment.rcs.oppy.ai/',
        },
    },
};

export interface IPerEnvironmentAppConfiguration {
    local: IAppConfiguration,
    development: IAppConfiguration,
    staging: IAppConfiguration,
    production: IAppConfiguration,
}

export interface IAppConfiguration {
    api: {
        endpoint: string,
    },
}

// eslint-disable-next-line no-process-env
export const appConfiguration: IAppConfiguration = config[process.env.REACT_APP_ENV ?? 'local'];

