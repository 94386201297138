/* eslint-disable no-process-env */
import { useMemo } from 'react';

import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import agents, { EnvironmentType, IEnvironmentAgent } from '../agents';
import { AppContainer } from './common/container/AppContainer';
import { AppFooter } from './common/footer/AppFooter';
import { AppHeader } from './common/header/AppHeader';
import { Enroll } from './enroll/Enroll';
import { NotFound } from './notFound/NotFound';

export const App = () => {
    const agent = useMemo<IEnvironmentAgent[]>(() => agents[process.env.NODE_ENV as EnvironmentType], []);

    return (
        <>
            <AppContainer>
                <AppHeader />
                <BrowserRouter>
                    <Routes>
                        {
                            agent.map(({ agentId, token }) => (
                                <Route key={token} path={token} element={<Enroll agentId={agentId} />} />
                            ))
                        }
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
                <AppFooter />
            </AppContainer>
        </>
    );
};
