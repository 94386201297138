import { useCallback, useState } from 'react';

import { OmniButton } from '../../../components/ui/buttons/OmniButton';
import { Alert } from '../../../components/ui/texts/Alert';
import { Label } from '../../../components/ui/texts/Label';
import { Paragraph } from '../../../components/ui/texts/Paragraph';
import { startTest } from '../../../services/interactors/startTest';

interface IEnrollSuccessProps {
    agentId: string,
    phoneNumber: string,
    handleEnrollNewNumber: () => void,
}

export const EnrollSuccess = ({ agentId, phoneNumber, handleEnrollNewNumber }: IEnrollSuccessProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isInError, setIsInError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleStartText = useCallback(() => {
        const parameter = {
            phoneNumber,
            agentId,
        };

        setIsLoading(true);
        setIsInError(false);
        setIsSuccess(false);

        startTest(parameter)
            .then(() => {
                setIsSuccess(true);
            })
            .catch(() => {
                setIsInError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [phoneNumber, agentId]);

    return (
        <>
            <Alert severity="success">Success.</Alert>
            <Paragraph>
                {'You\'ve sucessfully enrolled your number.'}
            </Paragraph>
            <Label isBold>{phoneNumber}</Label>
            <Paragraph>
                {'You should receive an RCS communication on this number soon.'}
            </Paragraph>
            <Paragraph>
                {'Once you\'ve accepted the inbound RCS, start the test by clicking on the following button.'}
            </Paragraph>
            <OmniButton handleClick={handleStartText} disabled={isLoading}>Start Test</OmniButton>
            {
                isInError && (
                    <Alert severity="error">An error occurred.</Alert>
                )
            }
            {
                isSuccess && (
                    <Alert severity="success">Test launched.</Alert>
                )
            }
            <OmniButton variant="text" handleClick={handleEnrollNewNumber}>Enroll a new number</OmniButton>
        </>
    );
};
