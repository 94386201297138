import { startSingleConversation } from '../api/conversation/startSingleConversation';

interface IStartTestParam {
    phoneNumber: string,
    agentId: string,
}

const startTest = (param: IStartTestParam): Promise<void> => (
    startSingleConversation({
        msisdn: param.phoneNumber,
        agentId: param.agentId,
        broadcastToken: '',
        metadata: {
            NOM: 'Dupont',
        },
    })
);

export { startTest };
