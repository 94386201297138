import { axiosService } from '../axios';

interface IAddTesterDataContract {
    msisdn: string,
    agentId: string,
}

const addTester = (param: IAddTesterDataContract): Promise<void> => (
    axiosService.post('/tester/add', param)
);

export { addTester };
