import { ChangeEvent, FormEvent, useCallback, useState } from 'react';

import { FormContainer } from './FormContainer';

export interface IFormChildProps<T> {
    values: Partial<T>,
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
    setFieldValue: (name: string, value: unknown) => void,
    errors: IFormErrors<T>,
    hasValidationErrors: boolean,
}

export type IFormErrors<T> = Partial<{
    [Key in keyof T]: string | undefined
}>;

interface IFormControllerProps<T> {
    initialValues: Partial<T>,
    children: (props: IFormChildProps<T>) => React.ReactNode,
    onSubmit: (state: Partial<T>) => void,
    validator?: (values: Partial<T>) => IFormErrors<T>,
}

const hasAnyErrors = <T, >(errors: IFormErrors<T>): boolean => (
    errors !== undefined && !Object.values(errors).every((value) => value === undefined)
);

export const FormController = <T, >({
    initialValues,
    children,
    onSubmit,
    validator,
}: IFormControllerProps<T>) => {
    const [values, setValues] = useState<Partial<T>>(initialValues);
    const [errors, setErrors] = useState<IFormErrors<T>>({
    });
    const [hasValidationErrors, setHasValidationErrors] = useState(false);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setValues((oldValues) => ({
            ...oldValues,
            [event.target.name]: [event.target.value],
        }));
    }, []);

    const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setErrors({
        });
        setHasValidationErrors(false);

        if (validator !== undefined) {
            const validationErrors = validator(values);

            if (hasAnyErrors(validationErrors)) {
                setErrors(validationErrors);
                setHasValidationErrors(true);

                return;
            }
        }

        onSubmit(values);
    }, [onSubmit, values, validator]);

    const setFieldValue = useCallback((name: string, value: unknown) => {
        setValues((oldValues) => ({
            ...oldValues,
            [name]: value,
        }));
    }, []);

    return (
        <FormContainer onSubmit={handleSubmit}>
            {
                children({
                    values,
                    handleChange,
                    setFieldValue,
                    errors,
                    hasValidationErrors,
                })
            }
        </FormContainer>
    );
};
