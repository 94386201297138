import { useCallback, useState } from 'react';

import { EnrollForm } from './form/EnrollForm';
import { EnrollSuccess } from './success/EnrollSuccess';

interface IEnrollProps {
    agentId: string,
}

export const Enroll = ({ agentId }: IEnrollProps) => {
    const [isInSuccess, setIsInSuccess] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleEnrollNewNumber = useCallback(() => {
        setPhoneNumber('');
        setIsInSuccess(false);
    }, []);

    const handleEnrollSuccess = useCallback((enrolledPhoneNumber: string) => {
        setPhoneNumber(enrolledPhoneNumber);
        setIsInSuccess(true);
    }, []);

    return isInSuccess
        ? (
            <EnrollSuccess agentId={agentId} phoneNumber={phoneNumber} handleEnrollNewNumber={handleEnrollNewNumber} />
        )
        : (
            <EnrollForm agentId={agentId} handleEnrollSuccess={handleEnrollSuccess} />
        );
};
