import styled from '@emotion/styled';

export const AppContainer = styled.div`
max-width: 300px;
margin: auto;
display: flex;
flex-direction: column;
padding: 1em;
gap: 0.75em;
`;
